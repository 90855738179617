import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"

const ContactUsPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Contact Us"/>
      <div className="max-w-3xl mx-auto pt-12 pb-32">
        <h2 className="text-4xl font-semibold text-center mb-12">Contact us</h2>
        <ContactForm/>
      </div>
    </Layout>
  )
}

export default ContactUsPage